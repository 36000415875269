.makeFlex.spaceBetween {
  justify-content: space-evenly;
  max-width: 1300px;
  margin: 0 auto;
}
.makeFlex {
  display: flex;
  margin-top: 40px;
}
.makeFlexNew{
  margin-top: 15px;
}
input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  width: 1em;
  height: 1em;
  cursor: pointer;
}
.filterWrap {
  width: 230px;
  flex-shrink: 0;
  align-self: flex-end;
  position: sticky;
  position: -webkit-sticky;
  bottom: 110px;
  z-index: 1;
}
.appendRight30 {
  margin-right: 30px;
}

.appendBottom35 {
  margin-bottom: 15px;
}

.makeFlex.end {
  align-items: flex-end;
}
.makeFlex.spaceBetween {
  justify-content: space-evenly;
}
.appendBottom10 {
  margin-bottom: 10px;
}
.appendBottom15 {
  margin-bottom: 10px;
}

.blackText {
  color: #000000;
}
.latoBold {
  font-weight: 700;
}
.blackText {
  color: #000;
}
.latoBold {
  font-weight: 700;
}
.font20 {
  font-size: 24px;
  line-height: 20px;
}
.listingWrap {
  margin-top: 0px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 930px;
}

.makeFlex.hrtlCenter {
  align-items: center;
}
.filterList {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.filterList li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3px;
}
.filterList li .checkmarkOuter {
  flex-shrink: unset;
}

.filterList li .checkmarkOuter {
  flex: 1;
}
.filterList li .checkmarkOuter {
  flex-shrink: unset;
}
.filterList li .checkmarkOuter {
  flex: 1;
}
.checkmarkOuter {
  display: inline-flex;
  position: relative;
  min-height: 18px;
  flex-shrink: 0;
}

.grayText {
  color: #9b9b9b;
}

.cat_star {
  margin-left: 10px;
  position: absolute;
  font-size: 14px;
  color: #555;
  font-weight: 400;
  cursor: pointer;
}
.cat_starsmaill {
  margin-left: 20px;
  position: absolute;
  font-size: 12px;
  color: #555;
  font-weight: 400;
  cursor: pointer;
  font-family: sans-serif;
}

.star_score {
  right: 0;
  position: absolute;
}

.Container-Tile-NoHotel {
  margin-bottom: 20px;
  display: flex;
  height: 320px;
  flex-direction: column;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 0 6px 6px 0;
}

.hsw {
  padding: 11px 0;
  position: sticky;
  position: -webkit-sticky;
  max-width: 1300px;
  top: 68px;
  z-index: 10;
}

.hsw_viewMap {
  padding: 11px 0;
  position: sticky;
  position: -webkit-sticky;
  top: 68px;
  max-width: 1300px;
  z-index: 2;
  display: flex;
}

.hsw_viewMap_content {
  padding-left: 60px;
  padding-top: 10px;
}

.Listtext {
  padding: 8px;
  font-size: 25px;
  font-weight: 700;
  color: #deba56;
}
._Hlisting_header_viewMap {
  position: relative;
  position: -webkit-sticky;
  left: 0;
  top: -8px;
  background: #bfd7ed94;
  z-index: 2;
}
._Hlisting_header_viewMap_new {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  top: 65px;
  background: #bfd7ed;
  transition: all 0.4s ease-in-out;
  z-index: 2;
}
.miniMapCont_List {
  width: 150px;
  height: 40px;
  border-radius: 2px;
  margin-left: auto;
  margin-right: 65px;
  cursor: pointer;
  position: relative;
}
.miniMapCont_List > img {
  width: 100%;
}
.hsw .hsw_inner {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  align-items: center;
}

.hsw .hsw_inputBox {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  margin-right: 5px;
  width: 240px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}

.hsw .hsw_searchButton {
  width: 176px;
  font-size: 18px;
  margin-left: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-weight: bold;
}

.primaryBtn {
  border-radius: 34px;
  background-image: linear-gradient(-90deg, #deba56, #040404);
  box-shadow: 0 1px 7px 0 rgb(0 0 0 / 20%);
  display: inline-block;
  flex-shrink: 0;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  outline: 0;
  border: 0;
  text-align: center;
}

.hsw .hsw_inputBox.activeWidget .lbl_input,
.hsw .hsw_inputBox.activeWidget .hsw_inputField {
  opacity: 1;
}

.hsw .hsw_inputBox.activeWidget .lbl_input,
.hsw .hsw_inputBox.activeWidget .hsw_inputField {
  opacity: 1;
}

.hsw .hsw_inputField {
  border: 0;
  outline: 0;
  background: none;
  min-width: 140px;
  font-family: "lato";
  font-weight: 400;
  padding: 0 9px 4px 9px;
}
.whiteText {
  color: #fff !important;
}

.font16 {
  font-size: 16px;
  line-height: 16px;
}

.hsw .lbl_input {
  text-transform: capitalize;
  padding: 4px 9px 5px 9px;
}

.hsw .lbl_input {
  text-transform: capitalize;
  padding: 4px 9px 5px 9px;
}
.blueText {
  color: #349d59;
}
.latoBold {
  font-weight: 700;
}

.focused_div__focused {
  background: rgb(255, 255, 255) !important;
  border-radius: 32px !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 20px !important;
  max-height: calc(100vh - 220px) !important;
  overflow: hidden auto !important;
  padding: 10px 20px !important;
  font-size: 20px !important;
}

.quantity-input {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 3px;
}
.quantity-input:focus {
  background: red;
}

.quantity-input__modifier,
.quantity-input__screen {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
}

.quantity-input__modifier {
  padding: 0.7rem;
  width: 3rem;
  font-size: 1.5rem;
  background: #f3f3f3;
  color: #888;
  border: 0 solid #dbdbdb;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
  cursor: pointer;
}
.quantity-input__modifier:hover {
  background: #dadada;
  color: #555555;
}
.quantity-input__modifier--left {
  border-radius: 3px 0 0 3px;
}
.quantity-input__modifier--right {
  border-radius: 0 3px 3px 0;
}

.quantity-input__screen {
  width: 4rem;
  padding: 0.7rem;
  font-size: 1.5rem;
  border: 0;
  border-top: 0 solid #dbdbdb;
  border-bottom: 0 solid #dbdbdb;
  text-align: center;
}

.panel-dropdown-content {
  position: absolute !important;
  left: 0px !important;
  top: 100% !important;
  z-index: 1 !important;
  background: rgb(255, 255, 255) !important;
  border-radius: 32px !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 20px !important;
  margin-top: 17px !important;
  max-height: calc(100vh - 220px) !important;
  overflow: hidden auto !important;
  padding: 16px 32px !important;
}

.qtyButtons span {
  outline: 0;
  font-size: 16px;
  font-size: 1rem;
  text-align: center;
  width: 50px;
  height: 36px !important;
  color: #333;
  line-height: 36px;
  margin: 0 !important;
  padding: 0 5px !important;
  border: none;
  box-shadow: none;
  pointer-events: none;
  display: inline-block;
  border: none !important;
}

.qtyDec1 {
  width: 36px;
  height: 36px;
  font-size: 10px;
  border: 1px solid #555;
  background-color: #f2f2f2;
  border-radius: 50%;
  cursor: pointer;
  font-family: "ElegantIcons";
}

.qtyInc1 {
  width: 36px;
  height: 36px;
  font-size: 15px;
  border: 1px solid #555;
  background-color: #f2f2f2;
  border-radius: 50%;
  cursor: pointer;
  font-family: "ElegantIcons";
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  font-size: 19px;
  font-weight: 500;
}
.nav-tabs .nav-item {
  margin-bottom: 10px;
  cursor: pointer;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
}

.nav-span.active {
  height: 2.3px;
  width: 45px;
  background: #fff;
  position: absolute;
  margin-top: 10px;
  /* margin: 0 auto; */
}

.react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open {
  max-height: 300px;
  overflow-y: auto;
}

.react-autosuggest__suggestions-list li .noChild {
  padding: 4px 10px;
  cursor: pointer;
}

.noChild {
  margin-bottom: 10px;
  padding-top: 5px;
}
.font14 {
  font-size: 14px !important;
  line-height: 14px;
}
.makeFlex.spaceBetween {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-evenly;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-evenly;
}
.makeFlex.hrtlCenter {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.makeFlex .flexOne {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.appendBottom5 {
  margin-bottom: 5px !important;
}
.textRight {
  text-align: right !important;
}
.appendLeft10 {
  margin-left: 10px !important;
}
.lightGreyText {
  color: #9b9b9b !important;
}
.latoBold {
  font-weight: 700 !important;
}
.makeFlex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.locusLabel {
  font-size: 13px !important;
  text-align: left !important;
  line-height: 13px !important;
}

.panel-dropdown {
  position: relative;
  text-align: left;
  padding: 2px 0px 0 0px;
  font-size: 14px;
  font-weight: 400;
}

.hotelListing {
  border-bottom: 1px solid #eee;
}

.hotelListingItem {
  display: flex;
  padding: 10px;
  cursor: pointer;
}

.hotelListingItem:hover {
  background-color: #f9ecc94a;
}

.listingImg {
  width: 82px;
  height: 82px;
  margin-right: 15px;
  overflow: hidden;
  border-radius: 4px;
}

.listingImg img {
  width: 100%;
  height: 100%;
}

.listingDetails {
  flex: 1;
}

.makeFlex1.hrtlCenter {
  align-items: center;
}

.hotelRatingTa {
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 3px;
}

.whiteText {
  color: #fff;
}

.font9 {
  font-size: 9px;
  line-height: 9px;
}

.greenBgTA {
  background: #00b087;
}

.CloseModal {
  margin: 10px;
  font-size: 19px;
  color: red;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.sprite.icTaRating {
  width: 18px;
  height: 11px;
  background-position: -206px -328px;
}
.sprite.icTaRating {
  width: 18px;
  height: 11px;
  background-position: -206px -328px;
}

.sprite {
  background: url(../assets/images/hotelSprite.png) no-repeat;
  display: inline-block;
  background-size: 450px 750px;
  font-size: 0px;
  flex-shrink: 0;
}

.appendRight3 {
  margin-right: 3px;
}

.latoBold {
  font-weight: 700;
}

.appendLeft5 {
  margin-left: 5px;
}

.blackText {
  color: #000;
}

.latoBold {
  font-weight: 700;
}
.font14 {
  font-size: 14px;
  line-height: 14px;
}

.appendBottom3 {
  margin-bottom: 3px;
}
.makeFlex1 {
  display: flex;
}
.flexOne {
  flex: 1;
}

.font11 {
  font-size: 11px;
  line-height: 11px;
}
.latoBlack {
  font-weight: 900;
}
.appendBottom5 {
  margin-bottom: 5px;
}
.lineThrough {
  text-decoration: line-through;
}

.textRight {
  text-align: right !important;
  font-size: 12px !important;
}

.autocomplete-wrapper {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  width: 316px;
  position: absolute;
  top: 40px;
  left: 0px;
  min-height: 316px;
  overflow: hidden;
  z-index: 999999;
}
.customSelectTitle {
  position: relative;
  padding-right: 25px;
  cursor: pointer;
}
.customSelectTitle:after {
  border: solid #008cff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  right: 9px;
  top: 5px;
  content: "";
}

.customSelectOptions {
  background: #fff;
  border: 1px #efefef solid;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  border-radius: 4px;
  position: absolute;
  left: 110px;
  top: 45px;
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.customSelectOptions li {
  padding: 5px 15px;
  white-space: nowrap;
  cursor: pointer;
}

.customSelectOptions li:hover {
  color: #fff;
  background-color: #008cff;
}
.selectedActiveSort {
  color: #fff;
  background-color: #008cff;
}

/* .roomsGuestsList {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  position: absolute;
  left: -250px !important;
  top: 1px;
  width: 560px;
  z-index: 10;
} */
.roomsGuestsList {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  position: absolute;
  left: -155px !important;
  top: 18px;
  width: 500px;
  z-index: 10;
}
.hotellisting #start-date-input-button .selected-date {
  color: #fff !important;
  font-size: 16px !important;
  margin-bottom: 3px !important;
  margin-top: -5px;
  min-width: 140px;
  font-family: "lato";
  font-weight: 400;
  padding: 0 9px 4px 6px;
}

.react-google-flight-datepicker
  .hotellisting
  .dialog-date-picker.open
  #start-date-input-button
  .selected-date {
  color: #000 !important;
  font-size: 25px !important;
  margin-bottom: 3px !important;
  margin-top: 0px;
  /* font-weight: 500; */
  min-width: 140px;
  font-family: "lato";
  font-weight: 400;
  padding: 0 9px 4px 6px;
}

.hotellisting #end-date-input-button .selected-date {
  color: #fff !important;
  font-size: 16px !important;
  margin-bottom: 3px !important;
  margin-top: -5px;
  min-width: 140px;
  font-family: "lato";
  font-weight: 400;
  padding: 0 9px 4px 6px;
  justify-content: flex-end;
}

.react-google-flight-datepicker
  .hotellisting
  .dialog-date-picker.open
  #end-date-input-button
  .selected-date {
  color: #000 !important;
  font-size: 25px !important;
  margin-bottom: 3px !important;
  margin-top: 0px;
  /* font-weight: 500; */
  min-width: 140px;
  font-family: "lato";
  font-weight: 400;
  padding: 0 9px 4px 6px;
}

.checkinandcheckout {
  display: flex;
  justify-content: space-between;
  /* right: 10px; */
  padding-right: 13px;
}

.margin_30_95 {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 0px;
}

.appliedFiltersContainer {
  background: #fff;
  padding: 20px 0 10px;
  position: sticky;
  position: -webkit-sticky;
  /* top: 110px;
  z-index: 1; */
  margin-bottom: -40px;
}

.capText {
  text-transform: uppercase;
  color: #008cff;
}

.appliedFilters li {
  align-items: center;
  background-color: #eaf6ff;
  border-radius: 4px;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  display: inline-flex;
  margin: 0 10px 10px 0;
  padding: 5px;
  width: 100%;
  border: 1px solid #008cff;
  justify-content: space-between;
}

.appliedFilters li a {
  background-color: #c2c2c2;
  border-radius: 50%;
  flex-shrink: 0;
  height: 13px;
  margin-left: 5px;
  position: relative;
  width: 13px;
}

.appliedFilters li a:before {
  transform: rotate(45deg);
}
.appliedFilters li a:after {
  transform: rotate(-45deg);
}

.appliedFilters li a:after,
.appliedFilters li a:before {
  background-color: #fff;
  content: " ";
  height: 7px;
  left: 6px;
  position: absolute;
  top: 3px;
  width: 1px;
}

.input-range__label--max,
.input-range__label--min {
  display: none;
  position: absolute;
  top: 0;
}

.input-range__label {
  color: #4a4a4a;
  font-size: 12px;
  transform: translateZ(0);
  white-space: nowrap;
}

.input-range__label-container {
  left: -15px;
  position: absolute;
  top: 10px;
  display: none;
}

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%;
}

.input-range__track {
  background: #e6e6e6;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 6px;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out;
}

.input-range__track--active {
  background: #008cff;
}

.input-range__slider-container {
  transition: left 0.3s ease-out;
}

.input-range__slider-container:first-of-type .input-range__label--value {
  left: 20px !important;
}

.input-range__slider,
.input-range__slider:focus {
  box-shadow: 0 2px 4px 0 hsl(0deg 0% 55% / 30%),
    0 -2px 4px 0 hsl(0deg 0% 55% / 20%);
}

.input-range__slider {
  appearance: none;
  background: #fff;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 25px;
  margin-left: -12px;
  margin-top: -16px;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 25px;
}

.minValue {
  left: 0;
}
.maxValue,
.minValue {
  position: absolute;
  margin-top: 5px;
}

.maxValue {
  right: 0;
}
.input-range {
  height: 1rem;
  position: relative;
  width: 95%;
  margin: 0 auto;
}

.covidCard,
.covidCard.high {
  background: linear-gradient(90deg, #fff, #dae8f559);
}

.covidCard {
  border: 1px solid #cecece;
  border-radius: 4px;
  padding: 20px;
}

.covidCard__header {
  margin-bottom: 13px;
}

.lineHight20 {
  line-height: 20px !important;
}

.covidCard__desc {
  display: flex;
  justify-content: space-between;
}
.covidCard__list2 {
  padding-left: 3px !important;
  margin-top: 5px !important;
}
.covidCard__list2 li {
  align-items: center;
  display: inline-flex;
  font-size: 14px;
  margin-right: 25px;
}
.appendRight5 {
  margin-right: 5px;
}

.covidCard__learnMore {
  align-self: flex-end;
  margin-left: 20px;
  text-transform: uppercase;
}
.latoBold {
  font-weight: 700;
}

.font20 {
  font-size: 20px;
  line-height: 20px;
}
.font36 {
  font-size: 36px;
  line-height: 36px;
}
.alignSelfBottom {
  align-self: flex-end;
}
p.latoBlack.font22.blackText.appendBottom12 span {
  font-size: 18px;
}
.d-flax.justifi-content-center {
  text-align: center;
  border: none;
  border-radius: 10px;
  padding: 4px 40px;
  width: 800px;box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin: 0px auto;
}
.soldoutcontent h3{
  margin: 0px;
  padding-bottom: 15px;
  font-size: 22px;
  font-weight: 600;
  color: #0076b7;
}
.soldoutcontent p{
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  line-height: 23px;
  padding: 0px 0px 15px 0px;
}
.hGXiWF {
  color: #4a652f;
  display: inline-block;
  border-radius: 8px;
  font-size: 10px;
  padding: 2px 8px;
  letter-spacing: .8px;
  font-weight: 400;
  border: 1px solid #7DAA50;
  margin: 3px;
  }

