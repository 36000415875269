.Detail-container{
    display: flex;
    flex-flow: column;
}

.Detail-container .Horizontal-nav{

    width: 100%;
    position: fixed;
    background: #f9ecc9;
    height: 55px;
    padding-left: 40px;
    z-index: 2;
    top: -30px;
}
.Nav {
 display: none;
}

.Detail-container .Horizontal-nav ul.nav-container{
    padding: 11px 0;
    position: sticky;
    position: -webkit-sticky;
    max-width: 1300px;
    z-index: 2;
    display: flex;
    /* justify-content: flex-start; */
}

.Detail-container .Horizontal-nav ul.nav-container li {
    margin-left: 15px;
    width: 140px;
    font-weight: bold;
    font-size: 14px !important;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.Detail-container .Horizontal-nav ul.nav-container li a{
    color: black;
    transition: all 0.3s ease-in-out;
}


.Detail-container .Horizontal-nav ul.nav-container li a:hover{
    color: #000080;
    transition: all 0.3s ease-in-out;
}


.Detail-container .hotel-details{
    display: flex;
    flex-flow: column;
    margin-left: 100px;
    width: 50%;
}

.Detail-container .hotel-details img{
    height: 100%;
    width: 100%;
}
.Detail-container .hotel-details h1{
    align-items: center;
}

.Detail-container .hotel-details .Text-Details{
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    margin-top: 50px;
    padding: 40px;
}

/* .Detail-container .hotel-details .Text-Details h4{
}
.Detail-container .hotel-details .Text-Details p{
    font-size: 15px;
} */

.Detail-container .hotel-details .Text-Details ol li{
    list-style: square;
}
.Detail-container .hotel-details .Image-details{
    border:1px solid #f1f1f1;
    padding: 20px;
    margin-top: 240px;
}

.Detail-container .hotel-details .Reviews{
    border:1px solid #f1f1f1;
    padding: 20px;
    display: flex;
    flex-flow: column;
    margin-top: 40px;
}


.Detail-container .hotel-details .Reviews .review-overview-card{
    background-color: #deb956;
    width: 30%;
    height: 120px;
    border-radius: 5px;
    display: flex;
}

.Detail-container .hotel-details .Reviews .review-overview{
    display: flex;
}

.Detail-container .hotel-details .Reviews .review-overview .review-overview-card{
    display: flex;
    flex-flow: column;
    align-items: center;
}


.Detail-container .hotel-details .Reviews .review-overview .review-overview-card h1,
.Detail-container .hotel-details .Reviews .review-overview .review-overview-card h4,
.Detail-container .hotel-details .Reviews .review-overview .review-overview-card h6{
    color: white;
}

.Detail-container .hotel-details .Reviews .review-overview .progressBar{
    width: 70%;
}

.Detail-container .hotel-details .Reviews .review-overview .progressBar progress{
    width: 100%;
    color: blanchedalmond;
    height: 20px;
}

.Detail-container .hotel-details .Reviews .all-reviews{
    display: flex;
    flex-flow: column;
}

.Detail-container .hotel-details .Reviews .all-reviews .people-reviews{
    margin-top: 40px;
    display: flex;
    flex-flow: row;
    padding: 40px;
}

.Detail-container .hotel-details .Reviews .all-reviews .people-reviews img{
    width: 10%;
    height: 10%;
    margin-right: 15px;
    border-radius: 5px;
}

.Detail-container .hotel-details .Reviews .all-reviews .people-reviews .the-review{
    width: 80%;
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    padding: 20px;
}

.ImageModal .ReactModal__Content {
    position: absolute;
    inset: 40px;
    border: none !important;
    background: transparent !important;
    /* overflow: hidden !important; */
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    border-radius: 4px;
    outline: none;
    padding: 20px;
    width: 100%;
    margin: 0 auto;
  }
  .ImageModal .ReactModal__Content::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Edge */
  }


.Detail-container .hotel-details .form{
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 20px;
}

.Detail-container .hotel-details .form input, .Detail-container .hotel-details .form textarea{
    border: thin solid black;
    border-radius: 4px;
    padding: 1px;
}


.Detail-container .hotel-details .form #Email{
    width: 60%;
}

.Detail-container .hotel-details .form #reviewText{
    padding-top: 0 !important;
    padding-left: 0 !important;
    
    width: 80%;
    height: 200px;
}

.Detail-container .hotel-details .form .submitBtn{
    background-color: #BFD7ED;
    padding: 10px 26px;
    color: white;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    position: relative;
    left: 65%;
}

.Detail-container .hotel-details .form .submitBtn:hover{
    border: 1px solid #deb956;
    color: #deb956;
    background-color: white;
    transition: all 0.3s ease-in-out;
}


.Detail-container .hotel-details .form input:focus{
    outline: blue;
}


.hotel-details #facilities {
    display: flex;
    flex-flow: column;
}

.hotel-details #facilities .facilities-container{
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 40px;
}

.hotel-details #facilities .facilities-container .left,.hotel-details #facilities .facilities-container .right {
    display: flex;
    flex-flow:column;
}
.hotel-details #facilities  .main-info{
    margin-bottom: 40px;
}
.hotel-details #facilities  .main-info h4{
    margin-bottom: 20px;
}

.hotel-details #facilities .Booking-Details table{
    margin-top: 40px;
}
.hotel-details #facilities .Booking-Details table tr td{
    border: 1px solid #f1f1f1 !important;
} 
/* @media(max-width:1440px)
{
    .Detail-container .hotel-details .form{
        margin-left: 80px;
        
    }

    .Detail-container .hotel-details .form input{
        margin-top: 12px;
    }
    .Detail-container .hotel-details .form label{
        margin-top: 10px;
    }
} */


.hotel-details .Policies{
    margin-top: 40px;
    border: 1px solid #f1f1f1;
    padding: 20px;
}


.DetailPageTitle{

    display: flex;
    margin-bottom: 4px;
    justify-content: space-between;
}
.title_dt{
    color:#000;
    font-size: 18px;
    font-weight: bold;
}

.hsw {

    padding: 11px 0;
    position: sticky;
    position: -webkit-sticky;
    max-width: 1300px;
    top: 68px;
    z-index: 10;
}

.hsw_viewMap {
    
    padding: 11px 0;
    position: sticky;
    position: -webkit-sticky;
    top: 68px;
    max-width: 1300px;
    z-index: 2;
    display: flex;
}

.hsw_viewMap_content{
    padding-left: 60px;
    padding-top: 10px;
    
}

.Listtext{
    padding:8px;
    font-size: 25px;
    font-weight: 700;
    color: #deba56
}
.Detail-container .hotel-details {
    display: flex;
    flex-flow: column;
    margin-left: 0px !important;
    width: 100% !important;
}

._Hlisting_header_viewMap{
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    top: 146px;
    background: #bfd7ed94;
    z-index: 2;
}
.miniMapCont {
    width: 150px;
    height: 40px;
    border-radius: 2px;
    margin-left: auto;
    margin-right: 65px;
    cursor: pointer;
    position: relative;
}
.hsw .hsw_inner {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    align-items: center;
}

.hsw .hsw_inputBox {
    background: rgba(255,255,255, 0.1);
    border-radius: 4px;
    margin-right: 5px;
    width:310px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    height:48px;
}

.hsw .hsw_searchButton {
    width: 225px;
    font-size: 18px;
    margin-left: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
}

.primaryBtn {
    border-radius: 5px;
    background-image: linear-gradient( 
        -90deg
        , #BFD7ED, #0c91fe);
    box-shadow: 0 1px 7px 0 rgb(0 0 0 / 20%);
    display: inline-block;
    flex-shrink: 0;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    outline: 0;
    border: 0;
    text-align: center;
}

.hsw .hsw_inputBox.activeWidget .lbl_input, .hsw .hsw_inputBox.activeWidget .hsw_inputField {
    opacity: 1;
}

.hsw .hsw_inputBox.activeWidget .lbl_input, .hsw .hsw_inputBox.activeWidget .hsw_inputField {
    opacity: 1;
}

.hsw .hsw_inputField {
    border: 0;
    outline: 0;
    background: none;
    min-width: 140px;
    font-family: 'lato';
    font-weight: 400;
    padding: 0 9px 4px 9px;
}
.whiteText {
    color: #fff;
}

.font16 {
    font-size: 16px;
    line-height: 16px;
}

.hsw .lbl_input {
    text-transform: capitalize;
    padding: 4px 9px 5px 9px;
}

.hsw .lbl_input {
    text-transform: capitalize;
    padding: 4px 9px 5px 9px;
}
.blueText {
    color: #040404;
}
.latoBold {
    font-weight: 700;
}

.focused_div__focused{

    background: rgb(255, 255, 255) !important;
    border-radius: 32px !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 20px !important;
    max-height: calc(100vh - 220px) !important;
    overflow: hidden auto !important;
    padding: 10px 20px !important;
    font-size: 20px !important;
}


.quantity-input {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border-radius: 3px;
  }
  .quantity-input:focus {
    background: red;
  }
  
  .quantity-input__modifier,
  .quantity-input__screen {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    outline: none;
  }

  .Image_showAll_btn{
    background-color: #fff;
    position: relative;
    width: 12%;
    height: 40px;
    /* margin-top: -61px; */
    /* right: 130px; */
    border: 1px solid #040404;
    border-radius: 10px;
    z-index: 1;
    width: 62%;
    margin: -51px auto;
  }

  .Image_showAll_content{ 
  margin: 7px auto;
  text-align: center;
  }

  .GalleryImg{
    display: flex;
    width: 100%;
    height: 350px;
  }
  .GalleryBig_img{
    width: 50%;
    padding-right:5px;
    cursor:pointer;
  }

  .Gallery_images_Large{
    object-fit: cover;
    width: 575px !important;
  }

  .Gallery_images_Small{
    object-fit: cover;
    height: 170px !important;
    width: 285px !important;
  }

  .GallerySmall_img{
    width: 50%; 
    display: flex;
    padding-left: 5px
  }

  .GallerySmallerSection{
    height: 175px;
    cursor: pointer;
  }
  .font13 {
    font-size: 13px;
    line-height: 13px;
}

.facilityLists li {
    display: flex;
    margin-bottom: 12px;
    position: relative;
}

.sprite.facilityTickIcon {
    width: 11px;
    height: 10px;
    background-position: -303px -288px;
}

.facilityLists span.facilityName {
    word-break: break-word;
}

.facilityGroupCol {
    margin-bottom: 30px;
    width: 300px;
    display: inline-block;
    padding: 0 15px 0 0;
}
.latoBold {
    font-weight: 700;
}

.blackText {
    color: #000;
}

.latoBlack {
    font-weight: bold;
}
.font22 {
    font-size: 22px;
    line-height: 22px;
}


.jCollA {
    display: flex;
    justify-content: space-around;
    padding: 20px 1px 5px 0px;
    background: rgb(255, 255, 255);
}

.textHeader{
    padding-bottom: 15px;
    border-bottom: 0.1rem solid rgb(225, 231, 238);
}

.KuomZ {
    flex: 1 1 0%;
    padding: 1rem;
}
.dqqjQn {
    list-style-type: none;
}
.kYbWuz {
    font-size: 1.6rem;
    padding: 0px 0px 1rem;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
}

._navSticky.navSticky {
    position: sticky;
    position: -webkit-sticky;
    background: #fff;
    left: 0;
    top: 66px;
    z-index: 9;
    box-shadow: 0 8px 8px 5px rgb(138 107 118 / 8%);
}
._navSticky.navSticky ul {
    width: 1200px;
    margin: 0 auto;
}

._navSticky.navSticky li {
    display: inline-block;
    vertical-align: top;
}

._navSticky.navSticky li a {
    display: flex;
    width: 170px;
    justify-content: center;
    font-weight: 800;
    padding: 16px 0 14px 0;
    text-transform: uppercase;
    color: #4a4a4a;
    cursor: pointer;

}

.GalleryHeader{
    position: relative;
    box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
    background-color: #ffffff;
    padding: 20px;
    border-radius: 15px;
    width: 1200px;
}

.dot {
    width: 6px;
    height: 6px;
    background-color: #9b9b9b;
    border-radius: 3px;
    display: inline-block;
    margin: 0 5px;
    vertical-align: middle;
}

._navSticky.navSticky li a.active {
    color: #0074b7 !important;
    border-bottom: solid 2px #0074b7;
}

.lineHight20 {
    line-height: 20px !important;
    text-align: justify;
}

.GoogleMapLo{
    display: flex;
    padding: 20px;
    margin-top: 20px;
    height: 450px;
    position: relative;
    margin-bottom: 40px;
    border-radius: 15px;
    box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
}
.GoogleInner{
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
}
.txtHeading.reviewTxtHeading {
    padding-bottom: 2px;
}

.txtHeading.reviewTxtHeading {
    padding-bottom: 2px;
}
.txtHeading {
    line-height: 27px;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}

.TAReviewTop {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.TAReviewLeft {
    width: 415px;
    display: flex;
    border-right: 1px #ececec solid;
    padding-right: 30px;
    align-items: center;
}


.ratingBox {
    width: 130px;
    margin-right: 20px;
    border: 1px #deba56 solid;
    border-radius: 4px;
    overflow: hidden;
}
.greenBgTA {
    background: #deba56;
}
.yellowText{
    color: #deba56;
}
.ratingBoxTop {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 10px;
}

.ratingBoxTop span:first-child {
    font-size: 42px;
    font-weight: 700;
}

.ratingBoxBottom {
    background: #fff;
    padding: 8px 10px;
    display: flex;
    align-items: center;
}

.sprite.taratingIconNew {
    width: 25px;
    height: 16px;
    background-position: -298px -268px;
}

.ta_rating_blank {
    width: 63px;
    height: 14px;
    background-position: -127px -170px;
    text-align: left;
}

.ta_rating_fill.ratingFour_half {
    width: 58px;
}
.ta_rating_fill {
    width: 0px;
    height: 14px;
    background-position: -127px -184px;
}

.latoBold {
    font-weight: 700;
}

.font11 {
    font-size: 11px;
    line-height: 11px;
}
.capText {
    text-transform: uppercase;
}

.spacing {
    height: 1px;
    background: #ececec;
    flex: 1;
    margin: 0 10px;
}

.font10 {
    font-size: 10px;
    line-height: 10px;
}

.TAReviewRight {
    flex: 1;
    flex-shrink: 0;
}

.TAReviewRight ul {
    display: flex;
}

.TAReviewRight ul li {
    flex: 1;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ta_rating_blank {
    width: 63px;
    height: 14px;
    background-position: -127px -170px;
    text-align: left;
}

.ta_rating_fill.ratingFive {
    width: 63px;
}

.TAReviewRow {
    background-color: #ffffff;
    padding: 25px 30px;
    background: #fff;
    margin-bottom: 30px;
    border-radius: 15px;
    box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
    text-align: justify;
}

.TAReviewRow h4 {
    margin-bottom: 3px;
}

.ratingNew{
    margin-left: 10px;
    margin-top: -5px;
}

.hdmakeFlex.spaceBetween {
    justify-content: space-between;
}
.hdmakeFlex.hrtlCenter {
    align-items: center;
}

.hdmakeFlex {
    display: flex;
}

.aboutDesc > p > span {
    color: #555 !important;
}

html[dir=ltr] .appendRight10 {
    margin-left: 12%;
}
.luxe .abpp__bottom--left {
    border: 1px solid #edb466;
}
.abpp__bottom--left {
    align-items: center;
    border: 1px solid #b1b3b5;;
    border-radius: 4px;
    display: flex;
    padding: 16px;
    margin-left: 13%;
    width: 75%;
    margin-bottom: 2%;
}
.abpp__bottom--left ul {
    display: flex;
    flex-direction: column;
}
.abpp__bottom--left li:not(:last-child) {
    margin-bottom: 8px;
}
.abpp__bottom--left li {
    color: #000;
    display: inline-flex;
    font-size: 16px;
    line-height: 22px;
}
.latoblacknearby {
    font-weight: 600;
    padding-left: 5px;
    font-size: 14px;
    font-style: normal;
    color: #0076b7;
    letter-spacing: 0px;
}
.nearbyleft{
    align-items: center;
    border: 1px solid #b1b3b5;
    border-radius: 4px;
    display: flex;
    padding: 16px;
    margin-left: 13%;
    width: 75%;
    margin-bottom: 2%;
}
.ril__image, .ril__imagePrev, .ril__imageNext {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: none;
    -ms-content-zooming: none;
    -ms-user-select: none;
    -ms-touch-select: none;
    -ms-touch-action: none;
    touch-action: none;
}
