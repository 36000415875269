.CalendarDay__selected_span {
  background: #82e0aa; 
  color: white; 
  border: 1px solid #c1c1c1; 
}

.CalendarDay__selected:hover {
  background: orange;
  color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: brown;
}

.DayPicker__withBorder {
  box-shadow: 0 2px 6px rgba(0,0,0,.05), 0 0 0 1px rgba(0,0,0,.07);
  border-radius: 3px;
  width: 710px !important;
  padding: 35px;
}

td, tr{
  border:none !important;
}
.DateInput {
  margin: 0;
  padding: 0;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 160px;
  
  vertical-align: middle;
}

.DateRangePickerInput__withBorder {
  border-radius: 0px;
   border: none !important;
}

/* .dialog-date-picker.open .dialog-footer{
  display: none;
} */

.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: middle;
  color: #484848;
  margin-right: 15px;
}
.DateRangePicker{
  margin-left:-50px;
}

.focused_div__focused{

  background: rgb(255, 255, 255) !important;
  border-radius: 32px !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 20px !important;
  max-height: calc(100vh - 220px) !important;
  overflow: hidden auto !important;
  padding: 10px 20px !important;
  font-size: 20px !important;
}


.quantity-input {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 3px;
}
.quantity-input:focus {
  background: red;
}

.quantity-input__modifier,
.quantity-input__screen {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: none;
}

.quantity-input__modifier {
  padding: 0.7rem;
  width: 3rem;
  font-size: 1.5rem;
  background: #f3f3f3;
  color: #888;
  border: 0 solid #dbdbdb;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
  cursor: pointer;
}
.quantity-input__modifier:hover {
  background: #dadada;
  color: #555555;
}
.quantity-input__modifier--left {
  border-radius: 3px 0 0 3px;
}
.quantity-input__modifier--right {
  border-radius: 0 3px 3px 0;
}

.quantity-input__screen {
  width: 4rem;
  padding: 0.7rem;
  font-size: 1.5rem;
  border: 0;
  border-top: 0 solid #dbdbdb;
  border-bottom: 0 solid #dbdbdb;
  text-align: center;
}

.panel-dropdown-content{
  position: absolute !important;
  left: 0px !important;
  top: 100% !important;
  z-index: 1 !important;
  background: rgb(255, 255, 255) !important;
  border-radius: 32px !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 20px !important;
  margin-top: 12px !important;
  max-height: calc(100vh - 220px) !important;
  overflow: hidden auto !important;
  padding: 16px 32px !important;
}

.qtyButtons span {
  outline: 0;
  font-size: 16px;
  font-size: 1rem;
  text-align: center;
  width: 50px;
  height: 36px !important;
  color: #333;
  line-height: 36px;
  margin: 0 !important;
  padding: 0 5px !important;
  border: none;
  box-shadow: none;
  pointer-events: none;
  display: inline-block;
  border: none !important;
}

.qtyDec1{
  width: 36px;
  height: 36px;
  font-size: 10px;
  border: 1px solid #555;
  background-color: #f2f2f2;
  border-radius: 50%;
  cursor: pointer;
  font-family: 'ElegantIcons';
}

.qtyInc1{
  width: 36px;
  height: 36px;
  font-size: 15px;
  border: 1px solid #555;
  background-color: #f2f2f2;
  border-radius: 50%;
  cursor: pointer;
  font-family: 'ElegantIcons';
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #fff;
  background-color: transparent; 
  border-color: transparent;
      font-size: 19px;
  font-weight: 500;
}
.nav-tabs .nav-item {
  margin-bottom: 10px;
  cursor: pointer;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.nav-span.active{
    height: 2.3px;
  width: 45px;
  background: #fff;
  position: absolute;
  margin-top: 10px;
  /* margin: 0 auto; */
}

.react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open {
max-height: 300px;
overflow-y: auto;
}

.react-autosuggest__suggestions-list li .noChild {
padding: 4px 10px;
cursor: pointer;
}

.hw__recentLocationIcon {
  width: 19px;
  height: 22px;
  background-position: -71px -3px;
}
/* .widgetSpriteNew {
  background: url(//imgak.mmtcdn.com/pwa_v3/pwa_hotel_assets/sprite/spriteModifySearchNew1.png) no-repeat;
  display: inline-block;
  background-size: 100px 100px;
  font-size: 0;
  flex-shrink: 0;
} */

.widgetSpriteNew{
  font-size: 16px;
  margin-right: 5px;
  position: relative;
  padding-top: 10px;
  color: #ccc;
}
.noChild {
margin-bottom: 10px;
padding-top: 5px;
}
.font14 {
font-size: 14px !important;
line-height: 14px;
}
.hsFlex.hsFlexspaceBetween {
-webkit-box-pack: justify;
-webkit-justify-content: space-between;
-moz-box-pack: justify;
-ms-flex-pack: justify;
justify-content: space-between;
}
.hsFlex.hrtlCenter {
-webkit-box-align: center;
-webkit-align-items: center;
-moz-box-align: center;
-ms-flex-align: center;
align-items: center;
}
.hsFlex .flexOne {
-webkit-box-flex: 1;
-webkit-flex: 1;
-moz-box-flex: 1;
-ms-flex: 1;
flex: 1;
}
.appendBottom5 {
margin-bottom: 5px !important;
}
.textRight {
text-align: right !important;
font-size: 12px !important;
}
.appendLeft10 {
margin-left: 10px !important;
}
.lightGreyText {
color: #9b9b9b !important;
}
.latoBold {
font-weight: 700 !important;
}
.hsFlex {
display: -webkit-box;
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
}
.locusLabel {
font-size: 14px !important;
padding: 0px 0px 0px 0px!important;
text-align: left !important;
line-height: 14px !important;
}

.font20 {
  font-size: 20px;
  line-height: 20px;
}

.blackText {
  color: #000;
}
.hsw_inputBox .lineHeight36 {
  line-height: 36px;
}

.latoBlack {
  font-weight: 900;
}
.lineHeight36 {
  line-height: 36px !important;
}
.code{
  font-size: 15px;
  width: 200px;
  text-align: justify;

}
.latoBlack {
  font-weight: 900;
}

.font30 {
  font-size: 30px !important;
  line-height: 30px;
}
.totalsercheng .Allhover:hover{
  background-color: #cbe0f3;
  transition: all 0.3s ease-in-out;
}
.totalsercheng .Allhover{
  cursor: pointer;
  transition: all 0.3s ease-in-out;

}

.daterangepicker .drp-selected {
  display: inline-block !important;
  font-size: 12px;
  padding-right: 8px;
  float: initial;
  color: #040404;
}

 .react-autosuggest__suggestions-list li {
  padding: 0px;
  cursor: pointer;
}

.react-autosuggest__suggestions-list li:hover {
  background: #f2f2f2;
}

.greyText {
  --borderColor: #9b9b9b;
}

/* .hsw_sectionTitle {
  text-transform: uppercase;
  padding: 10px;
} */
/* .hsw_sectionTitle {
  text-transform: uppercase;
  padding: 10px;
} */
.latoBlack {
  font-weight: 900;
}
.greyText {
  color: #9b9b9b;
}
.hsw_sectionTitle {
  /* text-transform: uppercase;
  padding: 10px; */
  padding: 10px;
  color: black !important;
    opacity: 0.2 !important;
    font-weight: 700 !important;
}
.latoBlack {
  font-weight: 900;
}

.react-google-flight-datepicker .selected-date {
  display: flex;
  flex: 1 1;
  align-items: center;
  height: 100%;
  font-size: 25px !important;
  color: #000 !important;
  float: left;
  font-weight: bold;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.react-google-flight-datepicker .date {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  padding: 3px !important;
  flex-grow: 0!important;
  width: auto!important;
  background: transparent !important;
 border-radius: 0px  !important; 
}



.react-google-flight-datepicker .date-picker {
  position: relative;
  border-radius: 0px !important;
  background: white;
  padding: 0px !important;
  height: 0px;
}

.react-google-flight-datepicker .date:focus, .react-google-flight-datepicker .date.is-focus {
  background-color: transparent !important;
}

.react-google-flight-datepicker .date:focus::after, .react-google-flight-datepicker .date.is-focus::after {
  content: '';
  margin: -1px;
  border: none !important;
  border-radius: 4px;
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  color: #555;
  right: 0;
  z-index: 5;
  border-right: 0px solid #fff !important;
}

.react-google-flight-datepicker .change-date-group {
  width: 48px;
  display: none !important;
}

.react-google-flight-datepicker .icon-calendar {
  width: 24px;
  height: 24px;
  fill: #1a73e8;
  margin-right: 12px;
  display: none !important;
}
.react-google-flight-datepicker .date:first-child::before {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 1px;
  height: 70%;
  background: #dadce0;
}

.react-google-flight-datepicker .date-picker-input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 0px solid #dadce0 !important;
  border-radius: 4px;
  color: #3c4043;
  height: 30px !important;
  letter-spacing: 0.2px;
  font-size: 1rem;
  align-items: center;
  overflow: visible;
}


.react-google-flight-datepicker .my-own-class-name .date-picker-input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 0px solid #dadce0 !important;
  border-radius: 4px;
  color: #3c4043;
  /* height: 56px !important; */
  letter-spacing: 0.2px;
  font-size: 1rem;
  align-items: center;
  overflow: visible;
}

.react-google-flight-datepicker .date:first-child::before {
  content: '';
  position: absolute;
  right: 0;
  top: -83% !important;
  transform: none !important;
  width: 1px;
  height: 0px !important;
  background: #dadce0;
}

.react-google-flight-datepicker .dialog-date-picker {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgb(60 64 67 / 30%), 0 4px 8px 3px rgb(60 64 67 / 15%);
  min-width: 200px;
  position: absolute;
  top: -2px;
  /* left: -100px !important; */
  z-index: 10 !important;
  height: max-content;
  /* width: 726px !important; */
  /* margin-bottom: 50px; */
  transform-origin: top left;
  opacity: 0;
  transform: scale(0);
}

.submit-button {
  border-radius: 8px;
  padding: 10px 8px;
  border: none;
  line-height: 16px;
  text-transform: uppercase;
  color: #fff;
  background: #008cff;
  height: 35px;
  min-width: 88px;
  font-weight: 500;
  font-size: 0.9rem;
  cursor: pointer;
  text-align: center;
}

.react-google-flight-datepicker .dialog-footer {
  align-items: center;
  /* display: none !important; */
  flex-direction: row-reverse;
  padding: 8px;
}

.react-google-flight-datepicker .reset-button {
  font-size: 0.9rem;
  padding: 0 8px;
  height: 36px;
  border-radius: 4px;
  border: none;
  color: #5f6368;
  min-width: 64px;
  margin-left: 195px;
  outline: 0;
}
.roomsGuestsTop {
  /* padding: 15px 20px; */
  padding: 15px 20px;
  max-height: 60vh;
  overflow-y: auto;
}
.roomsRemove{
  position: absolute;
  color: #008cff;
  right: 15px;
  font-size: 12px;
  margin-top: 30px;
}

.addRoomRowhs:last-child {
  border-bottom: 0;
}
.addRoomRowhs {
  display: flex;
  flex-direction: column;
  padding: 1px 0;
  border-bottom: 1px #e7e7e7 solid;
}
.addRoomRowhs .addRoomLeft {
  width: 100px;
}

.latoBlack {
  font-weight: bold;
}

.font16 {
  font-size: 16px;
  line-height: 16px;
}
.appendBottom10 {
  margin-bottom: 10px;
}
.capText {
  text-transform: uppercase;
}

.addRoomRowhs .addRoomRight {
  flex: 1;
  flex-shrink: 0;
}
.hsFlex.hsFlexspaceBetween {
  justify-content: space-between;
}
.latoBold {
  font-weight: 700;
}
.addRoomRowhs .guestCounter {
  display: inline-flex;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 20%);
  margin-bottom: 24px;
  align-items: center;
}
.addRoomRowhs .guestCounter li {
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  width: 36px;
  height: 32px;
  position: relative;
  z-index: 2;
}
.addRoomRowhs .guestCounter li.selected {
  color: #fff;
  font-weight: 900;
}
.addRoomRowhs .guestCounter li.selected:after {
  width: 100%;
  height: 38px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background: #008cff;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 20%);
  z-index: -1;
}
.addRoomRowhs .guestCounter {
  display: inline-flex;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 20%);
  margin-bottom: 24px;
  align-items: center;
}
.addRoomRowhs .guestCounter li.selected {
  color: #fff;
  font-weight: 900;
}
.roomsGuestsBottom {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btnAddRoom {
  width: 200px;
  height: 32px;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 20%);
  background-color: #ffffff;
  border: solid 1px #008cff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  color: #008cff;
  outline: 0;
}
.btnApply {
  width: 88px;
  height: 32px;
}

.roomsGuests {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  position: absolute;
  /* left: -285px !important; */
  /* top: 35px; */
  right: 0;
  width: 560px;
  z-index: 10;
}

.editRoom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px #e7e7e7 solid;
  padding-bottom: 12px;
}

.hdhsFlex.end {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -moz-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.editRoomClass {
  color: #008cff !important;
  cursor: pointer !important;
  font-size: 13px !important;
  line-height: 13px;
}
.blackText {
  color: #000;
}
.font16 {
  font-size: 16px;
  line-height: 16px;
}
.latoBlack {
  font-weight: 900;
}
.appendLeft10 {
  margin-left: 10px;
}

.locationImage{
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  background-color: #e7e7e7;
  border-radius: 10px;
  margin-right: 20px;
  
}
.locationImageLocation{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e7e7e7;
  border-radius: 10px;
  margin-right: 10px;
  
}



.tourImage img{
  height: 50px;
  width: 75px;
  flex-shrink: 0;
  margin-right: 20px;
}

.locationImageContent{
  overflow: hidden;
  background-size: cover !important;
  width: 30px;
}

.locationImageContent2{
  width: 80px;
  height: 50px;
  margin-right: 20px;
}

.activityDate{
  padding: 10px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  position: absolute;
  z-index: 5;
  top: 66%;
  /* right: 0; */
}

.DayPicker-Month{
  width: 300px !important;
}

.DayPicker-NavButton--prev {
  margin-right: 1.5em !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  background-color: #4A90E2;
  color: #F0F8FF !important;
}

.DayPicker-Day--today {
  color: #00457e !important;
  font-weight: 700;
}
.DayPicker-Day {
  display: table-cell;
  padding: 10px !important;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}
.fontSizeChange{
  font-size: 15px !important;
  text-align: left !important;
  line-height: 15px !important;
}

.widgetSection h1 {
  font-size: 14px;
  text-align: center;
  width: 100%;
}
#transport_type_id{
    width: 100%;
    height: 40px;
    color: black;
    float: left!important;
    margin: 0px;
    padding: 5px!important;
    box-shadow: none;
    text-shadow: none!important;
    font-weight: 400;
    font-family: 'Arimo', sans-serif;
    font-size: 15px;
    border-radius: 5px;
    border: none;
    outline: 0;
}
.newTransferDay{
  right:0;
}

