.Detail-container{
    display: flex;
    flex-flow: column;
}

.Detail-container .Horizontal-nav{

    width: 100%;
    position: fixed;
    background: #f9ecc9;
    height: 55px;
    padding-left: 40px;
    z-index: 2;
    top: -30px;
}
.Nav {
 display: none;
}

.Detail-container .Horizontal-nav ul.nav-container{
    padding: 11px 0;
    position: sticky;
    position: -webkit-sticky;
    max-width: 1300px;
    z-index: 2;
    display: flex;
    /* justify-content: flex-start; */
}

.Detail-container .Horizontal-nav ul.nav-container li {
    margin-left: 15px;
    width: 140px;
    font-weight: bold;
    font-size: 14px !important;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.Detail-container .Horizontal-nav ul.nav-container li a{
    color: black;
    transition: all 0.3s ease-in-out;
}


.Detail-container .Horizontal-nav ul.nav-container li a:hover{
    color: #000080;
    transition: all 0.3s ease-in-out;
}


.Detail-container .hotel-details{
    display: flex;
    flex-flow: column;
    margin-left: 100px;
    width: 50%;
}

.Detail-container .hotel-details img{
    height: 100%;
    width: 100%;
}
.Detail-container .hotel-details h1{
    align-items: center;
}

.Detail-container .hotel-details .Text-Details{
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    margin-top: 50px;
    padding: 40px;
}

/* .Detail-container .hotel-details .Text-Details h4{
}
.Detail-container .hotel-details .Text-Details p{
    font-size: 15px;
} */

.Detail-container .hotel-details .Text-Details ol li{
    list-style: square;
}
.Detail-container .hotel-details .Image-details{
    border:1px solid #f1f1f1;
    padding: 20px;
    margin-top: 240px;
}

.Detail-container .hotel-details .Reviews{
    border:1px solid #f1f1f1;
    padding: 20px;
    display: flex;
    flex-flow: column;
    margin-top: 40px;
}


.Detail-container .hotel-details .Reviews .review-overview-card{
    background-color: #deb956;
    width: 30%;
    height: 120px;
    border-radius: 5px;
    display: flex;
}

.Detail-container .hotel-details .Reviews .review-overview{
    display: flex;
}

.Detail-container .hotel-details .Reviews .review-overview .review-overview-card{
    display: flex;
    flex-flow: column;
    align-items: center;
}


.Detail-container .hotel-details .Reviews .review-overview .review-overview-card h1,
.Detail-container .hotel-details .Reviews .review-overview .review-overview-card h4,
.Detail-container .hotel-details .Reviews .review-overview .review-overview-card h6{
    color: white;
}

.Detail-container .hotel-details .Reviews .review-overview .progressBar{
    width: 70%;
}

.Detail-container .hotel-details .Reviews .review-overview .progressBar progress{
    width: 100%;
    color: blanchedalmond;
    height: 20px;
}

.Detail-container .hotel-details .Reviews .all-reviews{
    display: flex;
    flex-flow: column;
}

.Detail-container .hotel-details .Reviews .all-reviews .people-reviews{
    margin-top: 40px;
    display: flex;
    flex-flow: row;
    padding: 40px;
}

.Detail-container .hotel-details .Reviews .all-reviews .people-reviews img{
    width: 10%;
    height: 10%;
    margin-right: 15px;
    border-radius: 5px;
}

.Detail-container .hotel-details .Reviews .all-reviews .people-reviews .the-review{
    width: 80%;
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    padding: 20px;
}


.Detail-container .hotel-details .form{
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 20px;
}

.Detail-container .hotel-details .form input, .Detail-container .hotel-details .form textarea{
    border: thin solid black;
    border-radius: 4px;
    padding: 1px;
}


.Detail-container .hotel-details .form #Email{
    width: 60%;
}

.Detail-container .hotel-details .form #reviewText{
    padding-top: 0 !important;
    padding-left: 0 !important;
    
    width: 80%;
    height: 200px;
}

.Detail-container .hotel-details .form .submitBtn{
    background-color: #BFD7ED;
    padding: 10px 26px;
    color: white;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    position: relative;
    left: 65%;
}

.Detail-container .hotel-details .form .submitBtn:hover{
    border: 1px solid #deb956;
    color: #deb956;
    background-color: white;
    transition: all 0.3s ease-in-out;
}


.Detail-container .hotel-details .form input:focus{
    outline: blue;
}


.hotel-details #facilities {
    display: flex;
    flex-flow: column;
}

.hotel-details #facilities .facilities-container{
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 40px;
}

.hotel-details #facilities .facilities-container .left,.hotel-details #facilities .facilities-container .right {
    display: flex;
    flex-flow:column;
}
.hotel-details #facilities  .main-info{
    margin-bottom: 40px;
}
.hotel-details #facilities  .main-info h4{
    margin-bottom: 20px;
}

.hotel-details #facilities .Booking-Details table{
    margin-top: 40px;
}
.hotel-details #facilities .Booking-Details table tr td{
    border: 1px solid #f1f1f1 !important;
} 
/* @media(max-width:1440px)
{
    .Detail-container .hotel-details .form{
        margin-left: 80px;
        
    }

    .Detail-container .hotel-details .form input{
        margin-top: 12px;
    }
    .Detail-container .hotel-details .form label{
        margin-top: 10px;
    }
} */


.hotel-details .Policies{
    margin-top: 40px;
    border: 1px solid #f1f1f1;
    padding: 20px;
}


.DetailPageTitle{
    display: flex;
    margin-bottom: 12px;
    justify-content: space-between;
}
.title_dt{
    color:#000;
    font-size: 18px;
    font-weight: bold;
}

.hsw {

    padding: 11px 0;
    position: sticky;
    position: -webkit-sticky;
    max-width: 1300px;
    top: 68px;
    z-index: 10;
}

.hsw_viewMap {
    
    padding: 11px 0;
    position: sticky;
    position: -webkit-sticky;
    top: 68px;
    max-width: 1300px;
    z-index: 2;
    display: flex;
}

.hsw_viewMap_content{
    padding-left: 60px;
    padding-top: 10px;
    
}

.Listtext{
    padding:8px;
    font-size: 25px;
    font-weight: 700;
    color: #00a1cd
}

.Detail-container .hotel-details {
    display: flex;
    flex-flow: column;
    margin-left: 0px !important;
    width: 100% !important;
}
._Hlisting_header_viewMap {
    position: relative;
    position: -webkit-sticky;
    left: 0;
    top: -8px;
    background: #bfd7ed94;
    z-index: 2;
  }

.miniMapCont {
    width: 150px;
    height: 40px;
    border-radius: 2px;
    margin-left: auto;
    margin-right: 65px;
    cursor: pointer;
    position: relative;
}
.hsw .hsw_inner {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    align-items: center;
}

.hsw .hsw_inputBox {
    background: rgba(255,255,255, 0.1);
    border-radius: 4px;
    margin-right: 5px;
    width:310px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    height:48px;
}

.hsw .hsw_searchButton {
    width: 225px;
    font-size: 18px;
    margin-left: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
}

.primaryBtn {
    border-radius: 5px;
    /* background-image: linear-gradient( 
        -90deg
        , #BFD7ED, #0c91fe); */
    background-image: linear-gradient(96deg, #bfd7ed, #53b2fe, #065af3);
    box-shadow: 0 1px 7px 0 rgb(0 0 0 / 20%);
    display: inline-block;
    flex-shrink: 0;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    outline: 0;
    border: 0;
    text-align: center;
}

.hsw .hsw_inputBox.activeWidget .lbl_input, .hsw .hsw_inputBox.activeWidget .hsw_inputField {
    opacity: 1;
}

.hsw .hsw_inputBox.activeWidget .lbl_input, .hsw .hsw_inputBox.activeWidget .hsw_inputField {
    opacity: 1;
}

.hsw .hsw_inputField {
    border: 0;
    outline: 0;
    background: none;
    min-width: 140px;
    font-family: 'lato';
    font-weight: 400;
    padding: 0 9px 4px 9px;
}
.whiteText {
    color: #fff;
}

.font16 {
    font-size: 16px;
    line-height: 16px;
}

.hsw .lbl_input {
    text-transform: capitalize;
    padding: 4px 9px 5px 9px;
}

.hsw .lbl_input {
    text-transform: capitalize;
    padding: 4px 9px 5px 9px;
}
.blueText {
 /* color: #040404; */
 color: #ffcf5d;
}
.latoBold {
    font-weight: 700;
}

.focused_div__focused{

    background: rgb(255, 255, 255) !important;
    border-radius: 32px !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 20px !important;
    max-height: calc(100vh - 220px) !important;
    overflow: hidden auto !important;
    padding: 10px 20px !important;
    font-size: 20px !important;
}


.quantity-input {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border-radius: 3px;
  }
  .quantity-input:focus {
    background: red;
  }
  
  .quantity-input__modifier,
  .quantity-input__screen {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    outline: none;
  }

  .Image_showAll_btn{
    background-color: #fff;
    position: relative;
    width: 12%;
    height: 40px;
    /* margin-top: -61px; */
    /* right: 130px; */
    border: 1px solid #040404;
    border-radius: 10px;
    z-index: 1;
    width: 62%;
    margin: -51px auto;
  }

  .Image_showAll_content{ 
  margin: 7px auto;
  text-align: center;
  }

  .GalleryImg{
    display: flex;
    width: 100%;
    height: 350px;
  }
  .GalleryBig_img{
    width: 50%;
    padding-right:5px;
    cursor:pointer;
  }

  .Gallery_images{
      width: 100%;
      height: 100% !important;
  }

  .GallerySmall_img{
    width: 50%; 
    display: flex;
    padding-left: 5px
  }

  .GallerySmallerSection{
    height: 175px;
    cursor: pointer;
  }
  .font13 {
    font-size: 13px;
    line-height: 13px;
}

.facilityLists li {
    display: flex;
    margin-bottom: 12px;
    position: relative;
}

.sprite.facilityTickIcon {
    width: 11px;
    height: 10px;
    background-position: -303px -288px;
}

.facilityLists span.facilityName {
    word-break: break-word;
}

.facilityGroupCol {
    margin-bottom: 30px;
    width: 300px;
    display: inline-block;
    padding: 0 15px 0 0;
}
.latoBold {
    font-weight: 700;
}

.blackText {
    color: #000;
}

.latoBlack {
    font-weight: bold;
}
.font22 {
    font-size: 22px;
    line-height: 22px;
}


.jCollA {
    display: flex;
    justify-content: space-around;
    padding: 20px 1px 5px 0px;
    background: rgb(255, 255, 255);
}

.textHeader{
    padding-bottom: 15px;
    border-bottom: 0.1rem solid rgb(225, 231, 238);
}

.KuomZ {
    flex: 1 1 0%;
    padding: 1rem;
}
.dqqjQn {
    list-style-type: none;
}
.kYbWuz {
    font-size: 1.6rem;
    padding: 0px 0px 1rem;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
}

._navSticky.navSticky {
    position: sticky;
    position: -webkit-sticky;
    background: #fff;
    left: 0;
    top: 66px;
    z-index: 9;
    box-shadow: 0 8px 8px 5px rgb(138 107 118 / 8%);
}
._navSticky.navSticky ul {
    width: 1200px;
    margin: 0 auto;
}

._navSticky.navSticky li {
    display: inline-block;
    vertical-align: top;
}

._navSticky.navSticky li a {
    display: flex;
    width: 170px;
    justify-content: center;
    font-weight: 800;
    padding: 16px 0 14px 0;
    text-transform: uppercase;
    color: #4a4a4a;
    cursor: pointer;

}

.GalleryHeader{
    position: relative;
    box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
    background-color: #ffffff;
    padding: 20px;
    border-radius: 15px;
    width: 1200px;
}

.dot {
    width: 6px;
    height: 6px;
    background-color: #9b9b9b;
    border-radius: 3px;
    display: inline-block;
    margin: 0 5px;
    vertical-align: middle;
}

._navSticky.navSticky li a.active {
    color: #ff904a !important;
    border-bottom: solid 2px #ff904a;
}

.lineHight20 {
    line-height: 20px !important;
    text-align: justify;
}

.GoogleMapLo{
    display: flex;
    padding: 20px;
    margin-top: 20px;
    height: 450px;
    position: relative;
    margin-bottom: 40px;
    border-radius: 15px;
    box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
}
.GoogleInner{
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
}
.txtHeading.reviewTxtHeading {
    padding-bottom: 2px;
}

.txtHeading.reviewTxtHeading {
    padding-bottom: 2px;
}
.aboutProp .txtHeading {
    line-height: 27px;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    color: #000;
}

.TAReviewTop {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.TAReviewLeft {
    width: 415px;
    display: flex;
    border-right: 1px #ececec solid;
    padding-right: 30px;
    align-items: center;
}


.ratingBox {
    width: 130px;
    margin-right: 20px;
    border: 1px #deba56 solid;
    border-radius: 4px;
    overflow: hidden;
}
.greenBgTA {
    background: #deba56;
}
.yellowText{
    color: #deba56;
}
.ratingBoxTop {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 10px;
}

.ratingBoxTop span:first-child {
    font-size: 42px;
    font-weight: 700;
}

.ratingBoxBottom {
    background: #fff;
    padding: 8px 10px;
    display: flex;
    align-items: center;
}

.sprite.taratingIconNew {
    width: 25px;
    height: 16px;
    background-position: -298px -268px;
}

.ta_rating_blank {
    width: 63px;
    height: 14px;
    background-position: -127px -170px;
    text-align: left;
}

.ta_rating_fill.ratingFour_half {
    width: 58px;
}
.ta_rating_fill {
    width: 0px;
    height: 14px;
    background-position: -127px -184px;
}

.latoBold {
    font-weight: 700;
}

.font11 {
    font-size: 11px;
    line-height: 11px;
}
.capText {
    text-transform: uppercase;
}

.spacing {
    height: 1px;
    background: #ececec;
    flex: 1;
    margin: 0 10px;
}

.font10 {
    font-size: 10px;
    line-height: 10px;
}

.TAReviewRight {
    flex: 1;
    flex-shrink: 0;
}

.TAReviewRight ul {
    display: flex;
}

.TAReviewRight ul li {
    flex: 1;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ta_rating_blank {
    width: 63px;
    height: 14px;
    background-position: -127px -170px;
    text-align: left;
}

.ta_rating_fill.ratingFive {
    width: 63px;
}

.TAReviewRow {
    background-color: #ffffff;
    padding: 25px 30px;
    background: #fff;
    margin-bottom: 30px;
    border-radius: 15px;
    box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
    text-align: justify;
}

.TAReviewRow h4 {
    margin-bottom: 3px;
}

.ratingNew{
    margin-left: 10px;
    margin-top: -5px;
}

.hdmakeFlex.spaceBetween {
    justify-content: space-between;
}
.hdmakeFlex.hrtlCenter {
    align-items: center;
}

.hdmakeFlex {
    display: flex;
}

.aboutDesc > p > span {
    color: #555 !important;
}

.Gallery_imagesNew{
    height: 175px;
    width: 100%;
}

.image-gallery-left-nav, .image-gallery-right-nav {
    padding: 0px !important; 
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff !important;
    display: block;
    height: 55px;
    opacity: .65;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 30px !important;
    width: 30px !important;
    color: #0074b7 !important;
}

.check-availability-wrapper {
    border: 1px solid #edf0f4;
    background-color: #f5f5f5;
    min-height: 100%;
    padding: 25px;
}


.text-left {
    text-align: left!important;
}

.mb-1, .my-1 {
    margin-bottom: .25rem!important;
}

.font-weight-bold {
    font-weight: 700!important;
}

.text-lowercase {
    text-transform: lowercase!important;
}

.price-font, .price-font del, .price-font div, .price-font span {
    font-family: Poppins,Helvetica,Arial,sans-serif;
    font-weight: 700;
}

.font-weight-normal {
    font-weight: 400!important;
}

.product-count-text {
    color: #2a2d32;
    font-weight: 400;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.dayPick{
    background-color: #fff;
    height: 45px;
    border: 1px solid #edf0f4;
    border-radius: 1px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding: 8px;
    cursor: pointer;
    margin-bottom: 30px;

}
.apply-button-changes-feature {
    background-color: #fff;
    border-radius: 3px;
}
.dayPickicon{
    font-size: 25px;
    margin-left: 10px;
    margin-right: 20px;
    color: #186b6d9c;

}

.dayPick p{
    font-size: 14px;
    text-align: left;
    color: #000;
    /* text-transform: capitalize; */
}

.activityDetailsDate{
    padding: 10px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
    position: absolute;
    z-index: 5;
    top: 158px;
}

.btn-block {
    display: block;
    width: 100%;
}
/* .btn-primary {
    color: #fff;
    background-color: #af143e;
    border-color: #af143e;
    cursor: pointer;
} */
.btn-primary {
    color: #fff !important;
    background-color: #4e9fcc;
    border-color: #00a1cd;
    cursor: pointer;
   
}


.roomsGuestsListNew{
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
    position: absolute;
    top: -45px;
    width: 402px;
    z-index: 10;
    left: -25px;
}

.click-disabled {
    background-size: 24px 24px;
    background-position: 50%;
    cursor: not-allowed !important;
    background-repeat: no-repeat;
}

.btn-secondary {
    color: #fff;
    background-color: #186b6d;
    border-color: #186b6d;
    padding: 5px 10px 5px 10px;
}

.rowNew{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ErrM{
    color: crimson;
}

.OverviewData ul{
    margin-left: 20px;
    list-style: disc;
}

.Collapsible__trigger.is-closed{
   
    background:url(../../assets/images/down-arrow.png) center center no-repeat;
	background-size: 13px;
    background-position: 1140px 10px;
    cursor: pointer;
}

.Collapsible__trigger.is-open{
   
    background:url(../../assets/images/upload.png) center center no-repeat;
	background-size: 13px;
    background-position: 1140px 10px;
    margin-bottom: 20px;
    cursor: pointer;
}

.inclusionCheck{
    color: #186b6dbd;
    font-size: 20px;
    margin-right: 15px;
}
.inclusionName{
    font-size: 15px;
    text-align: left;
    line-height: 15px;
}

.exclusionCheck{
    color: #af143e;
    font-size: 20px;
    margin-right: 15px;
}

.Exclusion{
   margin-left: 30px;
}

.text-bodyNew{
    color: #55575b;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 7px;
}

.tg-container-left.long-button {
    width: 800px;
}

.pl-3, .px-3 {
    padding-left: 1rem!important;
}

.loading-wrapper {
    border: 1px solid #edf0f4;
}

.mb-3, .my-3 {
    margin-bottom: 1rem!important;
}

.loading-wrapper .loading-message {
    background-color: #f5f5f5;
    font-weight: 700;
    font-size: 1rem;
    padding-left: 1rem!important;
}

.tour-grade-loading-wrapper{
    max-width:1210px;
    margin-top: 40px;
}

.pax-container{
    border: 1px solid #e5e5e5;
    max-width:1190px;
    margin-top: 40px;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.justify-content-start {
    -webkit-box-pack: start!important;
    justify-content: flex-start!important;
}

.d-flex {
    display: -webkit-box!important;
    display: flex!important;
}

input[type=radio]:enabled, label.enabled {
    cursor: pointer;
}

.font-weight-medium {
    font-weight: 400;
    font-size: 15px;
}

.start-times {
    max-height: 40px;
    overflow: hidden;
    margin: -.5rem 0 0 -.5rem;
    width: calc(100% + .5rem);
}

.single-start-time.available.selected, .start-time.available.selected {
    background: #186b6d;
    color: #fff;
}

.single-start-time.available, .start-time.available {
    border: 1px solid #186b6d;
    padding: 5px 15px 5px 15px;
    margin-right: 10px;

}

.book-section {
    border-top: none;
    border-left: 2px solid #edf0f4;
}
.h-100 {
    height: 100%!important;
}

.add-to-cart-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
    min-height: 0;
}

.tour-option.chosen .cart-option-buttons {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
}

.text-nowrap {
    white-space: nowrap!important;
}
.pl-2, .px-2 {
    padding-left: .5rem!important;
}
.pb-2, .py-2 {
    padding-bottom: .5rem!important;
}

.rounded {
    border-radius: .1875rem!important;
}

.btn.disabled, .btn.disabled:hover, .btn:disabled, .btn:disabled:hover {
    cursor: not-allowed;
    background-color: #d6d6d6;
    border-color: #d6d6d6;
    color: rgba(0,0,0,.4);
    opacity: 1;
}
.btn.disabled, .btn:disabled {
    opacity: .65;
}

.option-pill {
    background-color: #f2f2f2;
    font-size: .875rem;
    padding-left: 6px;
    padding-right: 6px;
}

.price-break-down-cta img {
    height:9px;
    width:9px;
}
 /* BY Ranjeet */
.image-gallery-content {
    position: relative;
    line-height: 0;
    top: 0;
    width: 100%;
}

.OverviewData p{
   
    line-height: 20px;
}
.sectionWrapper {
    padding: 10px 0;
    display: inline-grid;
}
.section-title {
    font-weight: 500;
    line-height: normal;
    font-style: normal;
    letter-spacing: normal;
}
.section-title {
    font-size: 16px;
    color: #4a4a4a;
}
.whyShouldIDoItem {
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    margin: 7px 0;
    padding-right: 30px;
}
.beniftIcon {
    width: 21px;
    height: 21px;
    margin: 0 12px 0 0;
}
.whyShouldIDoItemText {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #242424;
}
.description, .description-more-text {
    font-size: 14px;
    font-style: normal;
    letter-spacing: normal;
}
.text-pre-wrap {
    white-space: pre-wrap;
}

.description > p{
    padding: 10px 0px 3px 0px;;
    line-height: 1.57;
    color: #4a4a4a;
}
.description > ul {
    list-style-type: disc;
    /* margin-left: 20px; */
}
.description > li {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    font-style: normal;
    letter-spacing: normal;
    padding: 5px 0;
    
}
.description > ol {
    list-style-type: disc;
    /* margin-left: 20px; */
}

.description > ol >li {
    list-style-type: disc !important;
    /* margin-left: 20px; */
    font-size: 14px;
    /* font-weight: 500; */
    margin-top: 3px;
    padding-top: 5px;
    margin-left: 40px;
}
.description > ul >li {
    list-style-type: disc !important;
    /* margin-left: 20px; */
    font-size: 14px;
    /* font-weight: 400; */
    margin-top: 3px;
    padding-top: 5px;
    margin-left: 40px;
}

/* .accordion.collapsed {
    background-image:  width='24' height='24'3E%3Cpath fill='%23186b6d' d='M12.003 14.75a2.497 2.497 0 01-1.77-.732l-4.08-3.908a.5.5 0 01.692-.722l4.086 3.914a1.505 1.505 0 002.129.008l4.093-3.922a.5.5 0 01.692.722l-4.086 3.914a2.477 2.477 0 01-1.757.725z'/%3E%3C/svg%3E);
} */
.accordion, .accordion.collapsed {
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 24px 24px;
}
.mb-5, .my-5 {
    margin-bottom: 2rem!important;
}
.collapse {
    display: none;
}
flex-column {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: normal!important;
    flex-direction: column!important;
}
.tour-itinerary-icon {
    margin-left: -0.5rem;
}
.tour-itinerary-title {
    line-height: 1.25;
}
.d-flex {
    display: -webkit-box!important;
    display: flex!important;
}
.d-inline-block {
    display: inline-block!important;
}
svg:not(:root) {
    overflow: hidden;
}
.fill-secondary {
    fill: #186b6d;
}
.mr-2, .mx-2 {
    margin-right: 0.5rem!important;
}
svg {
    vertical-align: middle;
}
tour-itinerary-item-label {
    background-color: #3fb3d2 !important;
    border-radius: 2px !important;
    color: #fff !important;
}
.tour-itinerary-container .tour-itinerary-stop:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,.03);
}
.collapse.show {
    display: block;
}
.tour-itinerary-icon {
    margin-left: -0.5rem;
}
.d-inline-block {
    display: inline-block!important;
}
.tour-itinerary-stop {
    white-space: nowrap;
}
.d-inline-block {
    display: inline-block!important;
}

.btn-link:hover {
    color: #0056b3;
    text-decoration: none;
}
.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 2000ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}
.ReactModal__Overlay .ReactModal__Overlay--after-open{
    position: fixed;
    inset: 0px;
    background-color: rgba(255, 255, 255, 0.75);
    left: 72%;
}

.sliderHeader {
    width: 100%;
    height: 48px;
    padding: 10px;
    background-color: #4789c8 !important;
    color: white;
    display: grid;
    align-items: center;
}
.closeBtn {
    display: block;
    position: absolute;
    right: 10px;
    top: 1px;
    padding: 10px;
    cursor: pointer;
    z-index: 2;
    color: #fff;
}

.no-time-selected-popover{
    width: 60%;
    /* margin-left: auto;
    margin-right: auto; */
    margin-left: 60%;
}
.noavailable{
    font-size: 20px;
    color: #f50000;
    font-weight: 500;
}
p.latoBlack.font22.blackText.appendBottom12 span {
    font-size: 18px;
}
.freeCancellationDetails{
    color: #758d5d;
    display: inline-block;
    border-radius: 8px px;
    font-size: 12px;
    padding: 2px 8px;
    letter-spacing: .7px;
    font-weight: 400;
    border: 1px solid #7DAA50;
    /* margin-left: 3px; */
    margin-top: 10px;
    border-radius: 8px;
    font-weight: 400;
}
.image-gallery-index {
    background: rgb(138 204 240) !important;
    color: #fff !important;
    line-height: 1 !important;
    padding: 5px 10px !important;
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
    z-index: 4 !important;
}
.mettingPointListing{
    display: flex;
    flex-direction: row;
    margin: 10px 10px 10px 0px;
}
.mettingPickupAddress{
    font-size: 15px;
    text-align: left;
    line-height: 23px;
}
.mettingPickupCheck {
    color: #f9cb5d;
    font-size: 25px;
    margin-right: 10px;
    border-radius: 50%;
    border: 1px solid;
    padding: 5px;
}
.meetingPint{
    margin-top:10px;
}
.itineraryDayHeader{
    margin-bottom: 10px;
    font-size: 16px;
    color: #695d44;
}
.itineraryWrapper{
    background: #fbf9f9;
    padding: 10px;
    margin: 10px 0px;
    border-radius: 2%;
    display: block;
}
.DayOneCard{
    border: 1px solid #ffc858;
    border-radius: 3%;
}
.marginTopBottom5{
    margin: 7px 0px;
}
.itineraryDuration{
    margin-top: 10px;
    color: #000;
    font-weight: 500;
}
.marginTop5{
    margin-top: 5px;
}
