.htSJWp {
  color: rgb(255, 255, 255);
  font-family: Lato, sans-serif;
  background-color: rgb(18, 18, 18);
  font-size: 14px;
}

.eAATEh {
  height: 514px;
  padding: 20px 0px;
  position: relative;
  background: url(https://hblimg.mmtcdn.com/content/hubble/img/maldives/mmt/destination/m_destination_Maldives_landscape_l_400_640.jpg)
    center center / 100% no-repeat;
}

.eAATEh::before {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: rgba(16, 16, 24, 0.7);
  content: '';
}

.jZqGFq {
  width: 1180px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: 1;
}

.cuPQgq {
  display: flex;
  flex: 1 1 0%;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.KXLh {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}

.KXLh h1 {
  font-size: 50px;
  font-weight: 900;
  margin-bottom: 5px;
  color: rgb(255, 255, 255);
}

.KXLh p {
  font-size: 18px;
  line-height: 22px;
  color: rgb(231, 231, 231);
}

.jSbUIF {
  width: 530px;
  margin-left: 100px;
  flex-shrink: 0;
  margin-top: 100px;
}

.rpoGx {
  height: 266px;
  border-radius: 3px 3px 0px 0px;
  overflow: hidden;
}

.eiNjYN {
  padding: 12px 15px;
  height: 74px;
  box-shadow: rgb(0 0 0 / 50%) 0px 1px 4px 0px;
  background-color: rgb(41, 41, 41);
}
.eiNjYN .slick-slider {
  padding: 0px;
  height: auto;
}
.eiNjYN .slick-initialized .slick-slide {
    margin-right: 10px;
    width: auto !important;
    padding: 0;
}
.eiNjYN .slick-initialized .slick-slide {
    display: block;
}
.eiNjYN .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}
.eiNjYN .slick-slider {
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.eiNjYN .slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
}
.eiNjYN img {
  width: 100px;
  height: 100%;
  border-radius: 5px;
  cursor: pointer;
}

.eiNjYN .slick-slider .slick-prev {
    width: 36px;
    height: 36px;
    -webkit-border-radius: 20px 0 0 20px;
    -moz-border-radius: 20px 0 0 20px;
    background-color: #292929;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: solid 1px #f1f1f1;
    border-right: none;
    -webkit-box-shadow: -1px 0px 3px 0 rgb(0 0 0 / 10%);
    -moz-box-shadow: -1px 0px 3px 0 rgba(0, 0, 0, 0.1);
    box-shadow: -1px 0px 3px 0 rgb(0 0 0 / 10%);
    left:-40px !important;
    border-radius: 20px !important;
}
.eiNjYN .slick-slider .slick-next {
    width: 36px;
    height: 36px;
    -webkit-border-radius: 20px 0 0 20px;
    -moz-border-radius: 20px 0 0 20px;
    background-color: #292929;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: solid 1px #f1f1f1;
    border-right: none;
    -webkit-box-shadow: -1px 0px 3px 0 rgb(0 0 0 / 10%);
    -moz-box-shadow: -1px 0px 3px 0 rgba(0, 0, 0, 0.1);
    box-shadow: -1px 0px 3px 0 rgb(0 0 0 / 10%);
    right:-40px !important;
    border-radius: 20px !important;
}
.eiNjYN .slick-slide.slick-active.slick-current > div > div {
    border: 1px solid rgb(255, 255, 255);
}
.slick-slider .slick-track, .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
    margin-top: 0px;
}
.kGtjKJ {
    height: 47px;
    overflow: hidden;
    border-radius: 3px;
    outline: 0px;
    cursor: pointer;
    width: 73px !important;
}

.bUCVot {
    width: 1180px;
    margin: -40px auto 0px;
    padding-bottom: 40px;
    position: relative;
    z-index: 1;
}

.hZjoUk {
    display: flex;
    padding: 0px 25px;
    margin-right: 20px;
    border-radius: 26px;
    border: 1px solid rgba(76, 76, 76, 0.8);
}
.hZjoUk a {
    font-size: 19px;
    color: rgb(255, 255, 255);
    padding: 13px 10px;
    margin-right: 15px;
    cursor: pointer;
}
.cEIUow {
    padding: 14px 10px;
    border-radius: 8px;
    box-shadow: rgb(18 18 18 / 50%) 0px 1px 11px 0px;
    background-color: rgb(37, 37, 37);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 15px;
    position: sticky;
    top: 64px;
    z-index: 2;
}
.fWTmyb {
    padding: 30px;
    border-radius: 10px;
    background-color: rgb(33, 33, 34);
    margin-bottom: 25px;
}
.eTZcwF {
    display: flex;
}


.eTZcwF a {
    font-size: 18px;
    font-weight: 700;
    color: rgb(255, 255, 255);
    padding: 0px 10px;
    margin-right: 15px;
    cursor: pointer;
}

.fWTmyb h2 {
    font-size: 28px;
    font-weight: 900;
    color: rgb(255, 255, 255);
    margin-bottom: 5px;
}

.fWTmyb h3 {
    font-size: 16px;
    color: rgb(231, 231, 231);
    font-weight: 400;
}

.ioGXEb {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding-top: 25px;
}
.txtHeading {
  line-height: 27px;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

._RoomType .roomheader {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: #f0f0f0;
  position: sticky;
  top: 120px;
  left: 0;
  width: 100%;
  border: solid 1px #bababa;
  display: flex;
  z-index: 1;
  color: #deba56;
}
._RoomType .roomWrap {
  display: flex;
  width: 100%;
  border-left: solid 1px #bababa;
  border-right: solid 1px #bababa;
  border-bottom: solid 8px #bababa;
  background: #fff;
}
