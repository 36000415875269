.Container-Tile {
  margin-bottom: 20px;
  display: flex;
  height: 270px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px 4px 0 0 !important;
}

.Container-Tile :hover {
  border: none;
}

.Container-Tile a {
  border-radius: 4px 4px 0 0 !important;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 8px 0 rgb(138 107 118 / 8%) !important;
}
.Container-Tile a:hover {
  background-color: #f4faff;
}
.Container-Tile a .Image-Section {
  padding: 20px;
  width: 30%;
  border: 1px solid transparent;
  display: flex;
  align-items: flex-start;
  height: 100%;
  flex-flow: column;
}
.text-Color {
  color: #000;
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
}

.ht-star {
  font-size: 10px;
  margin-top: -4px;
  line-height: 12px;
}
.sms {
  color: #008cff;
  font-weight: bold;
  font-size: 11px;
}

.Container-Tile a .Image-Section img {
  border-radius: 4px px 4px 0 0;
  border: 1px solid transparent;
  display: flex;
  align-items: flex-start;
  height: 100%;
}
/* .Image-Section .gallery__img {
  width: 100%;
  height: 160px;
} */
.Image-Section .gallery__img {
  height: 200px !important;
  width: 246px !important;
  object-fit: cover !important;
}

.Image-Section .imageSkeleton {
  width: 220px;
  height: 150px;
  margin-bottom: 5px;
}

.Container-Tile a .Text-Section {
  width: 70%;
  height: 100%;
  display: flex;
  border: 1px solid transparent;
  justify-content: space-evenly;
}
.matchback {
  border-color: #5bbaff;
  -webkit-box-shadow: 0 0 10px #5bbaff;
  box-shadow: 2px 2px 10px #5bbaff;
  background: #ebf3ff;
}
.Container-Tile a .Text-Section .Text-Contents {
  border: 1px solid transparent;
  width: 70%;
  text-align: left;
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  margin-top: 20px;
}

.Container-Tile a .Text-Section .Price {
  width: 30%;
  border: 1px solid transparent;
  text-align: center;
}

.Container-Tile a .Image-Section .SmallImage {
  display: flex;
}
.ReactModalPortal {
  position: fixed;
  z-index: 999;
}

.imgThumb {
  width: 54px;
  height: 50px;
  margin-right: 6px;
  overflow: hidden;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  opacity: 0.8;
  transition: all 0.3s linear;
}
.priceDetailsTop {
  padding: 20px 20px 0 20px;
}
.imgThumb_New {
  width: 51px !important;
  height: 45px;
  margin-right: 5px !important;
  overflow: hidden;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  opacity: 0.8;
  transition: all 0.3s linear;
}
.Container-Tile a .Image-Section .SmallImage img {
  margin-top: 5px;
}

.Container-Tile a .Text-Section .Text-Contents .Text-Ratings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
}

.Container-Tile a .Text-Section .Text-Contents .Text-Ratings div {
  margin-left: 12px;
}

.Container-Tile a .Text-Section .Text-Contents .Small-Text {
  display: flex;
  flex-flow: column;
}

.Container-Tile a .Text-Section .Text-Contents .Small-Text p {
  color: black;
}

.Container-Tile a .Text-Section .Text-Contents .Small-Text .Extras {
  display: flex;
  flex-flow: row;
}

.Container-Tile a .Text-Section .Text-Contents .Small-Text .highlight {
  background-color: #ebebeb;
  width: 40%;
  border-radius: 5px;
  text-align: center;
}
p.address {
  font-weight: 500;
}
.Container-Tile a .Text-Section .Text-Contents .logo {
  height: 14%;
  width: 15%;
}

.Container-Tile a .Text-Section .Price {
  display: flex;
  flex-flow: column;
  background: #f5f5f5;
  border-left: 1px #e6e6e6 solid;
  flex-shrink: 0;
  border-radius: 0 6px 6px 0;
  justify-content: baseline;
}

.Container-Tile a .Text-Section .Price h4 {
  margin-top: 150px;
}

.listingModal {
  width: 94%;
  height: 90%;
  display: flex;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #eee;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  overflow: hidden;
  margin: auto;
}

.listingModalLeft {
  width: 320px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.listingModalRight {
  flex: 1;
  border-left: 1px solid #eee;
}

.latoBold {
  font-weight: 700;
}

.font10 {
  font-size: 10px;
  line-height: 10px;
}
.capText {
  text-transform: uppercase;
}
.appendBottom3 {
  margin-bottom: 3px;
}
.latoBold {
  font-weight: 700;
}

.darkText {
  color: #4a4a4a;
}
.capText {
  text-transform: uppercase;
}
.darkText {
  color: #4a4a4a;
}
.font10 {
  font-size: 10px;
  line-height: 10px;
}

.includes li {
  position: relative;
  display: inline-flex;
  align-items: flex-start;
}

.includes .includeIcon {
  margin-top: 3px;
  margin-right: 5px;
  display: inline-flex;
  align-items: flex-start;
}

.includes .includes__text {
  font-size: 12px;
  line-height: 12px;
  color: #00a19c;
}
.textRight {
  text-align: right;
}
.makeFlex.column {
  flex-direction: column;
}
.padding20 {
  padding: 20px;
}
.priceDetails {
  width: 185px;
  background: #f5f5f5;
  border-left: 1px #e6e6e6 solid;
  flex-shrink: 0;
  border-radius: 0 6px 6px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-direction: column;
}

.makeFlex.hrtlCenter {
  align-items: center;
}

.makeFlex.right {
  justify-content: flex-end;
}

.redText {
  --borderColor: #eb2026;
  color: #eb2026;
}

.latoBold {
  font-weight: 700;
}
.appendLeft5 {
  margin-left: 5px;
}

.spriteNew.discountIcon {
  width: 38px;
  height: 17px;
  background-position: -206px -73px;
}

.spriteNew.discountIcon {
  width: 38px;
  height: 17px;
  background-position: -206px -73px;
}

.spriteNew {
  background: url(../../assets/images/hotelSprite.png) no-repeat;
  display: inline-block;
  background-size: 450px 750px;
  font-size: 0px;
  flex-shrink: 0;
}

.spriteNew.singleGreenTickIcon {
  width: 10px;
  height: 9px;
  background-position: -315px -217px;
}

._Hlisting .discountText {
  display: inline-block;
  position: absolute;
  left: 12px;
  top: 2px;
}
.greenText {
  --borderColor: #00a19c;
}
.font16 {
  font-size: 16px;
  line-height: 16px;
}

.appendBottom5 {
  margin-bottom: 5px;
}
.lineThrough {
  text-decoration: line-through;
}
.appendBottom5 {
  margin-bottom: 5px;
}
.blackText {
  color: #000;
}

.latoBlack {
  font-weight: 900;
}
.font26 {
  font-size: 26px;
  line-height: 26px;
}
.appendBottom5 {
  margin-bottom: 5px;
}

.darkGreyText {
  color: #4a4a4a;
}

.latoBlack {
  font-weight: bold;
}

.font16 {
  font-size: 16px;
  line-height: 16px;
}

.appendLeft20 {
  margin-left: 10px;
}

.noShrink {
  flex-shrink: 0;
  margin-top: -15px;
  margin-right: 10px;
}

.makeFlex.hrtlCenter {
  align-items: center;
}
.makeFlex.right {
  justify-content: flex-end;
}

.makeFlex.hrtlCenter {
  align-items: center;
}

.greenBgTA {
  background: #4f9fcd;
}

.spriteNew.taratingIconNew {
  width: 25px;
  height: 16px;
  background-position: -298px -268px;
}

.latoBlack {
  font-weight: 900;
}
.appendLeft3 {
  margin-left: 3px;
}

.latoRegular {
  font-weight: 400;
}
.font10 {
  font-size: 10px;
  line-height: 10px;
}

.whiteText {
  color: #fff;
}
.rating {
  border-radius: 4px;
  padding: 3px 5px;
  color: #fff;
}
.makeFlex.hrtlCenter {
  align-items: center;
}

.font11 {
  font-size: 11px;
  line-height: 11px;
}

.capText {
  text-transform: uppercase;
}
.blackText {
  color: #000;
}
.latoBold {
  font-weight: 700;
}

.blackText {
  color: #000;
}

.latoBlack {
  font-weight: bold;
}
.font22 {
  font-size: 22px;
  line-height: 22px;
}
.appendBottom12 {
  margin-bottom: 12px;
}

.appendLeft10 {
  margin-left: 10px;
}
.rating_blank {
  font-size: 10px;
  margin-left: 10px;
}

.SpecialOffer {
  color: rgb(139, 87, 42);
  border: 1px solid rgb(139, 87, 42);
  padding: 0px 10px;
}

.pc__cashbackDeal {
  align-items: center;
  background-image: linear-gradient(87deg, #cdf6e8, #c7efe5 41%, #bfe4e0);
  border: 1px solid #bfe4e0;
  border-radius: 0 0 4px 4px;
  border-top: 0;
  color: #1a7971;
  display: flex;
  font-size: 14px;
  font-weight: 900;
  padding: 11px 20px;
}

.title__placeHolder {
  margin-top: -20px;
  margin-bottom: 15px;
}
p.latoBlack.font22.blackText.appendBottom12 span {
  font-size: 18px;
}
.containerListing {
  display: inline-flex;
}

